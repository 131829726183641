import React from "react"
import { LinkItem } from "./customlink"
import { DayAndDate } from "./datetime"
import { renderAst } from "../utils/markdown.jsx"
import { H1, PX3, Max2xl, Max5xl } from "../components/blocks"
import { AuthorNames, ResonsibleAuthors } from "../components/author"

import { graphql } from "gatsby"
import DescribedPicture from "../components/described-picture"
import { AppointmentItem } from "./appointment"
import { getAppointmentSlug } from "../utils/slug"

export const PostItem = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug
  return (
    <LinkItem
      to={node.fields.slug}
      header={
        <DayAndDate
          className="text-primary-600"
          datetime={node.frontmatter.date}
        />
      }
      main={title}
      footer={
        <p
          className="mt-2 text-primary-600"
          dangerouslySetInnerHTML={{
            __html: node.frontmatter.description || node.excerpt,
          }}
        />
      }
    />
  )
}

export const Post = ({ node, next, previous, className = "" }) => (
  <div className={className}>
    <Max5xl className="text-primary-900 py-3">
      {node.fields.described_picture ? (
        <div className="py-3 sm:px-3">
          <DescribedPicture describedPicture={node.fields.described_picture} />
        </div>
      ) : (
        <div />
      )}
      <Max2xl>
        <PX3 className="pt-10">
          <div className=" text-primary-600">
            <AuthorNames authors={node.frontmatter.responsible_authors} />
            <DayAndDate datetime={node.frontmatter.date} />
          </div>
          <H1 margins="mt-4">{node.frontmatter.title}</H1>
          <div className="mt-2  text-lg text-primary-500">
            {node.frontmatter.description}
          </div>
          <div className="pt-6">{renderAst(node.htmlAst)}</div>
          <ResonsibleAuthors
            authors={node.frontmatter.responsible_authors}
            className="mt-6"
          />
        </PX3>
      </Max2xl>
    </Max5xl>
  </div>
)

export const PostIndex = ({ posts, className = "" }) => (
  <div className={className}>
    {posts.map(({ node, kind }) =>
      kind === "appointment" ? (
        <div key={node.fields.slug}>
          <AppointmentItem
            appointment={node.frontmatter}
            path={getAppointmentSlug(
              node.frontmatter.start,
              node.frontmatter.title
            )}
          />
        </div>
      ) : (
        <div key={node.fields.slug}>
          <PostItem node={node} />
        </div>
      )
    )}
  </div>
)

export const query = graphql`
  fragment PostIndexInformation on MarkdownRemarkConnection {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date
          title
          description
          archiveAt
          onFrontpageUntil
          state
        }
        fileAbsolutePath
      }
    }
  }

  fragment PostInformation on MarkdownRemarkConnection {
    edges {
      node {
        id
        excerpt(pruneLength: 160)
        htmlAst
        fields {
          described_picture {
            ...DescribedPicture
          }
        }
        frontmatter {
          title
          date
          description
          archiveAt
          onFrontpageUntil
          state
          responsible_authors {
            ...AuthorInformation
          }
          # coauthors {
          #  name
          #  firstname
          #  lastname
          # }
        }
      }
    }
  }
`
